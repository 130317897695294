/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Planner API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  UserDTOResetLink,
  UserDTOChangePassword,
  UserDTOTokenValidity,
  PostApiUserCheckResetTokenParams,
  UserDTO,
  UserDTOUpdate,
  UserDTODelete
} from './dotu.schemas'
import { dotuAxiosInstance } from '../../axios';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * None
 * @summary Request password reset link to be sent to specified email address.
 */
export const postApiUserPasswordResetLink = (
    userDTOResetLink: UserDTOResetLink,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/User/PasswordResetLink`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: userDTOResetLink
    },
      options);
    }
  


export const getPostApiUserPasswordResetLinkMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserPasswordResetLink>>, TError,{data: UserDTOResetLink}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserPasswordResetLink>>, TError,{data: UserDTOResetLink}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserPasswordResetLink>>, {data: UserDTOResetLink}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserPasswordResetLink(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiUserPasswordResetLinkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserPasswordResetLink>>>
    export type PostApiUserPasswordResetLinkMutationBody = UserDTOResetLink
    export type PostApiUserPasswordResetLinkMutationError = unknown

    /**
 * @summary Request password reset link to be sent to specified email address.
 */
export const usePostApiUserPasswordResetLink = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserPasswordResetLink>>, TError,{data: UserDTOResetLink}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiUserPasswordResetLinkMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * None
 * @summary Reset user password to specified.
 */
export const postApiUserResetPassword = (
    userDTOChangePassword: UserDTOChangePassword,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/User/ResetPassword`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: userDTOChangePassword
    },
      options);
    }
  


export const getPostApiUserResetPasswordMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: UserDTOChangePassword}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: UserDTOChangePassword}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserResetPassword>>, {data: UserDTOChangePassword}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserResetPassword(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiUserResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserResetPassword>>>
    export type PostApiUserResetPasswordMutationBody = UserDTOChangePassword
    export type PostApiUserResetPasswordMutationError = unknown

    /**
 * @summary Reset user password to specified.
 */
export const usePostApiUserResetPassword = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: UserDTOChangePassword}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiUserResetPasswordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * None
 * @summary Check reset token validity
 */
export const postApiUserCheckResetToken = (
    params?: PostApiUserCheckResetTokenParams,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<UserDTOTokenValidity>(
      {url: `/api/User/CheckResetToken`, method: 'post',
        params
    },
      options);
    }
  


export const getPostApiUserCheckResetTokenMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserCheckResetToken>>, TError,{params?: PostApiUserCheckResetTokenParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserCheckResetToken>>, TError,{params?: PostApiUserCheckResetTokenParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserCheckResetToken>>, {params?: PostApiUserCheckResetTokenParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiUserCheckResetToken(params,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiUserCheckResetTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserCheckResetToken>>>
    
    export type PostApiUserCheckResetTokenMutationError = unknown

    /**
 * @summary Check reset token validity
 */
export const usePostApiUserCheckResetToken = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserCheckResetToken>>, TError,{params?: PostApiUserCheckResetTokenParams}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiUserCheckResetTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary User - Read - Gets informations about logged user.
 */
export const getApiUser = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<UserDTO>(
      {url: `/api/User`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiUserQueryKey = () => [`/api/User`] as const;
  

    
export const getGetApiUserQueryOptions = <TData = Awaited<ReturnType<typeof getApiUser>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUser>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiUser>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUserQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUser>>> = ({ signal }) => getApiUser(requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUser>>>
export type GetApiUserQueryError = unknown

/**
 * @summary User - Read - Gets informations about logged user.
 */
export const useGetApiUser = <TData = Awaited<ReturnType<typeof getApiUser>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUser>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Update logged user info
 */
export const putApiUser = (
    userDTOUpdate: UserDTOUpdate,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<UserDTO>(
      {url: `/api/User`, method: 'put',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: userDTOUpdate
    },
      options);
    }
  


export const getPutApiUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUser>>, TError,{data: UserDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUser>>, TError,{data: UserDTOUpdate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUser>>, {data: UserDTOUpdate}> = (props) => {
          const {data} = props ?? {};

          return  putApiUser(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiUserMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUser>>>
    export type PutApiUserMutationBody = UserDTOUpdate
    export type PutApiUserMutationError = unknown

    /**
 * @summary Update logged user info
 */
export const usePutApiUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUser>>, TError,{data: UserDTOUpdate}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPutApiUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const deleteApiUser = (
    userDTODelete: UserDTODelete,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<boolean>(
      {url: `/api/User`, method: 'delete',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: userDTODelete
    },
      options);
    }
  


export const getDeleteApiUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiUser>>, TError,{data: UserDTODelete}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiUser>>, TError,{data: UserDTODelete}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiUser>>, {data: UserDTODelete}> = (props) => {
          const {data} = props ?? {};

          return  deleteApiUser(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiUser>>>
    export type DeleteApiUserMutationBody = UserDTODelete
    export type DeleteApiUserMutationError = unknown

    export const useDeleteApiUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiUser>>, TError,{data: UserDTODelete}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets informations about logged user
 */
export const getApiUserId = (
    id: number,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<UserDTO>(
      {url: `/api/User/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiUserIdQueryKey = (id: number,) => [`/api/User/${id}`] as const;
  

    
export const getGetApiUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiUserId>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUserIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUserId>>> = ({ signal }) => getApiUserId(id, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetApiUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUserId>>>
export type GetApiUserIdQueryError = unknown

/**
 * @summary Gets informations about logged user
 */
export const useGetApiUserId = <TData = Awaited<ReturnType<typeof getApiUserId>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiUserId>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiUserIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const deleteApiUserDeleteTestUsers = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/User/DeleteTestUsers`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiUserDeleteTestUsersMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserDeleteTestUsers>>, TError,TVariables, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserDeleteTestUsers>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiUserDeleteTestUsers>>, TVariables> = () => {
          

          return  deleteApiUserDeleteTestUsers(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiUserDeleteTestUsersMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiUserDeleteTestUsers>>>
    
    export type DeleteApiUserDeleteTestUsersMutationError = unknown

    export const useDeleteApiUserDeleteTestUsers = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiUserDeleteTestUsers>>, TError,TVariables, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getDeleteApiUserDeleteTestUsersMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    